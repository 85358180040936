import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import {
  ChatDto,
  ChatEntryDto,
  ChatEntryEvaluationDto,
  ChatEntryTraceDto,
  ChatEntryTraceKey,
  ChatPaginationResponseDto,
  ChatPerformanceDto,
  ChatReferenceDto,
  DehydratedChatEntryDto,
  LlmPromptDto,
  LlmResponseDto,
  RecommendationDto,
  RecommendationItemDto,
  VectorSearchDto,
  VectorSearchResponseDto,
} from './florence-facade.dtos';

export const fetchChats = (): Promise<ChatPaginationResponseDto & {
  content: ChatDto[];
}> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/florence/chats', null));
  });
};

export const deleteChats = (): Promise<void> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.delete('/florence/chats');
  });
};

export const postChat = (chat: Omit<ChatDto, 'id'>): Promise<ChatDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/florence/chat', { data: chat });
  });
};

export const putChat = (chat: ChatDto): Promise<ChatDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put(`/florence/chat/${chat.id}`, { data: chat });
  });
};

export const fetchChatEntries = (chatId: string): Promise<ChatPaginationResponseDto & {
  content: ChatEntryDto[];
}> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/florence/chat/${chatId}/entries`);
  });
};

export const postChatEntry = (entry: DehydratedChatEntryDto, chatId: number): Promise<ChatEntryDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/florence/chat/${chatId}/entry`, { data: entry });
  });
};

export const postChatPrompt = (entry: DehydratedChatEntryDto, chatId: number): Promise<ChatEntryDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/florence/chat/${chatId}/prompt`, { data: entry });
  });
};

export const postChatEntryShells = (entry: DehydratedChatEntryDto, chatId: number): Promise<ChatEntryDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/florence/chat/${chatId}/entry/shells`, { data: entry });
  });
};

export const putChatEntry = (entry: ChatEntryDto): Promise<ChatEntryDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/florence/chat/${entry.chatId}/entry/${entry.id}`, { data: entry });
  });
};

export const fetchChatEntry = (chatId: number, entryId: number): Promise<ChatEntryDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/florence/chat/${chatId}/entry/${entryId}`);
  });
};

export const fetchChatEntryTraces = (chatId: number, entryId: number): Promise<Partial<Record<ChatEntryTraceKey, ChatEntryTraceDto>>> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/florence/chat/${chatId}/entry/${entryId}/traces`);
  });
};

export const fetchAndPostProcessChatEntryReferences = (botEntryId: number): Promise<ChatReferenceDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/florence/chat/post-process/references', { botEntryId }));
  });
};

export const deleteChatEntry = (chatId: string, entryId: string): Promise<void> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.delete(`/florence/chat/${chatId}/entry/${entryId}`);
  });
};

export const postChatEvaluation = (chatId: number, evaluation: Partial<ChatEntryEvaluationDto>): Promise<ChatEntryEvaluationDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/florence/chat/${chatId}/entry/${evaluation.entryId}/evaluation`, { data: evaluation });
  });
};

export const putChatEvaluation = (chatId: number, evaluation: ChatEntryEvaluationDto): Promise<ChatEntryEvaluationDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put(`/florence/chat/${chatId}/entry/${evaluation.entryId}/evaluation/${evaluation.id}`, { data: evaluation });
  });
};

export const fetchChatEvaluations = (chatId: string): Promise<ChatEntryEvaluationDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/florence/chat/${chatId}/entry/evaluations`);
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchChatPerformance = (startDate: string, numOfDays: number, courseSectionIds: number[]): Promise<ChatPerformanceDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/florence/student-engagement', {
      data: {
        startDate,
        numOfDays,
        courseSectionIds
      }
    });
  });
};

export const fetchRemediationRecommendations = (assessmentId: number): Promise<RecommendationDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/florence/remediation/recommendation', { assessmentId }));
  });
};

export const fetchRemediationRecommendationItems = (remediationRecommendationId: number): Promise<RecommendationItemDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/florence/remediation/recommendation/items', { remediationRecommendationId }));
  });
};

export const adminSearchVector = (body: VectorSearchDto): Promise<VectorSearchResponseDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/florence/admin/hm-platform/search/vector', { data: body });
  });
};

export const adminPrompt = (body: LlmPromptDto): Promise<LlmResponseDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/florence/admin/hm-platform/prompt', { data: body });
  });
};

export const fetchChatEeoIsbns = (): Promise<string[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get('/florence/chat/eeoisbns');
  });
};
